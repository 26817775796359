@import url('https://fonts.googleapis.com/css?family=Open+Sans:wght@300;400;600;700;800&display=swap');

:root{
    --borda: 2px;
    -webkit-font-smoothing: antialiased;
}

.button{
   padding: 12px 24px;
   min-width: 132px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 0px;
   font-size: 1rem;
   border-radius: 100px;

   
   font-family: 'Open Sans', sans-serif;
   color: #ffff;
   font-weight: 600;
   line-height: 1rem;

}

.button span {
    display: inline-block;
    margin-right: 10px;
}

/*============================================
            PRIMARY BUTTON
=============================================*/

.button.primary{
    background-color: var(--primary);
}

/* Hover */
.button.primary:hover{
    background-color: var(--primary-dark);
}

/* Ativado */
.button.primary:active{
    background-color: var(--primary-light);
}

/* Desativado */
.button.primary:disabled{
   cursor: not-allowed;
   background-color: var(--primary);
   opacity: 0.4 !important ;
}

/*============================================
            OUTLINE BUTTON
=============================================*/

.button.outlined{
    border: var(--borda) solid var(--primary);
    background-color: var(--life-neutral-white);
    color: var(--primary);
}


/* Hover */
.button.outlined:hover{
    border: var(--borda) solid var(--primary-dark);
    /* background-color: var(--primary); */
    color: #2B45D4;
}

/* Ativo */
/* .button.outlined:active{
    border: var(--borda) solid var(--primary-light);
    background-color: rgba(204, 41, 110, 0.08);
    color: var(--primary-light) ;
} */

/* Desativado */
.button.outlined:disabled{
    opacity: 0.4 !important;
 }

/*============================================
            TEXT BUTTON
=============================================*/

.button.terciary{
    border: 2px solid transparent;
    background-color: transparent;
    color: var(--primary);
}


/* Hover */
.button.terciary:hover{
    border: 2px solid transparent;
    background-color: rgba(161, 34, 88, 0.08);
    color: var(--primary-dark) ;
}

/* Ativo */
.button.terciary:active{
    border: 2px solid transparent;
    background-color: rgba(204, 41, 110, 0.08);
    color: var(--primary-light) ;
}